import React, { FC } from 'react';
import './Compare.scss';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import ButtonAppleStore from '../ButtonAppleStore/ButtonAppleStore';

const Compare: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="compare">
      <Container>
        <div className="compare__table">
          <table>
            <thead>
              <tr>
                <th className="compare__name"></th>
                <th className="compare__active">Scanner PDF</th>
                <th>Other scanner</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="compare__name">{t('compare.megabyte')}</td>
                <td className="compare__active">64</td>
                <td>140</td>
              </tr>
              <tr>
                <td className="compare__name">{t('compare.premium')}</td>
                <td className="compare__active">
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
                <td>
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="compare__name">{t('compare.scan')}</td>
                <td className="compare__active">
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
                <td>
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="compare__name">{t('compare.sign')}</td>
                <td className="compare__active">
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
                <td>
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="compare__name">{t('compare.password')}</td>
                <td className="compare__active">
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
                <td>
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="compare__name">{t('compare.export')}</td>
                <td className="compare__active">
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
                <td>
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="compare__name">{t('compare.filters')}</td>
                <td className="compare__active">
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
                <td>
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="compare__name">{t('compare.folders')}</td>
                <td className="compare__active">
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
                <td>
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="compare__name">{t('compare.fileManager')}</td>
                <td className="compare__active">
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
                <td>
                  <svg>
                    <use xlinkHref="#no"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="compare__name">{t('compare.sorting')}</td>
                <td className="compare__active">
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
                <td>
                  <svg>
                    <use xlinkHref="#no"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="compare__name">{t('compare.friendlyDesign')}</td>
                <td className="compare__active">
                  <svg>
                    <use xlinkHref="#yes"></use>
                  </svg>
                </td>
                <td>
                  <svg>
                    <use xlinkHref="#no"></use>
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="compare__footer">
          <ButtonAppleStore
            color="#b17af6"
            href="https://apps.apple.com/us/app/scanner-pdf-app-lifescan/id1665727009"
            target="_blank">
            {t('downloadButton')}
          </ButtonAppleStore>
        </div>
      </Container>

      <svg style={{ display: 'none' }}>
        <symbol id="yes" viewBox="0 0 24 24">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#94D90A">
            <path d="M12,2C6.477,2,2,6.477,2,12c0,5.523,4.477,10,10,10s10-4.477,10-10C22,6.477,17.523,2,12,2z M10,17.414l-4.707-4.707 l1.414-1.414L10,14.586l7.293-7.293l1.414,1.414L10,17.414z"></path>
          </svg>
        </symbol>
        <symbol id="no" viewBox="0 0 24 24">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#FC4268">
            <path
              d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M15.7,14.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0
	L12,13.4l-2.3,2.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l2.3-2.3L8.3,9.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2.3,2.3
	l2.3-2.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L13.4,12L15.7,14.3z"
            />
          </svg>
        </symbol>
      </svg>
    </div>
  );
};

export default Compare;
