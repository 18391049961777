import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Intro from '../components/Intro/Intro';
import Advantages from '../components/Advantages/Advantages';
import Compare from '../components/Compare/Compare';
import ButtonAppleStore from '../components/ButtonAppleStore/ButtonAppleStore';

function HomePage({ title }: { title: string }) {
  const { t } = useTranslation();
  const introList: string[] = t('homeIntroList', { returnObjects: true });

  useEffect(() => {
    document.title = title;

    return () => {
      document.title = '';
    };
  }, [title]);

  return (
    <>
      <Intro
        title={
          <>
            Scanner PDF: Enjoy your Scans <span>{t('slogan')}</span>
          </>
        }
        type="accent">
        <p>{t('introTitle')}</p>
        <ul>
          {introList.map((item) => (
            <li key={item}>{item};</li>
          ))}
        </ul>

        <ButtonAppleStore
          color="#00d4d4"
          href="https://apps.apple.com/us/app/scanner-pdf-app-lifescan/id1665727009"
          target="_blank">
          {t('downloadButton')}
        </ButtonAppleStore>
      </Intro>

      <Advantages />

      <Compare />
    </>
  );
}

export default HomePage;
